import api from "@/api";
import { MonthData } from "@/api/modules/select";
import { DefaultSetting, ListOpions, MetaRow } from "@/models/type";
import { parseTime, sharer } from "@/utils";
import { setup, Vue } from "vue-class-component";
import { useMeta } from "vue-meta";
import { RouterView } from "vue-router";
import './index.scss'
// import Instagram from '@/assets/images/instagram.png'
import SvgIcon from "@/components/SvgIcon";

export default class ActivityActivity extends Vue {

  public monthList: Array<ListOpions> = []
  public monthId = 0
  public activeIndex = 0

  public monthActivityList  = [
    {
      id: 15,
      title: '中華文化課程',
      position: '香港九龍尖沙咀新東海商業中心一樓106-107室',
      introduce:  '空間舉辦了多個與中華文化有關的班組，包括中樂類、棋藝類、書畫類等等課程，一直以來廣受青少年的歡迎。以師傅帶徒弟的方式邀請駐場藝術家、訪問匠人、學者入駐空間，希望透過知識的傳播和教育讓年輕人有平台和機遇更加關注文化傳承。',
      coverImg: '/img/active/2.jpg',
      listImg: '/img/active/bg1.png'
    },
     {
      id: 16,
      title: '中華文化工作坊',
      position: '香港九龍尖沙咀新東海商業中心一樓106-107室',
      introduce:  '空間會定期舉辦不同的中華文化工作坊、新方式新類型活動，包括文化創意類、傳統手藝類、職業導向類，傳播中華文化正能量，吸引和鼓勵更多青年人創業，走進大灣區，走進大中華，展開社會服務工作，促進青少年的發展同增進社會共融。',
      coverImg: '/img/active/4.jpg',
      listImg: '/img/active/bg2.png'

    },
    {
      id: 17,
      title: '其他（展覽、比賽、合作活動等）',
      position: '香港九龍尖沙咀新東海商業中心一樓106-107室',
      introduce:  '空間會定期舉辦不同的畫展、影展、合辦活動、比賽等，透過各類活動建立青少年對中華文化自信，以文創、藝創、科創加上創新、創意、創業傳播中華優秀文化藝術的正能量，共同參與到弘揚中華優秀文化的事業，加快中華優秀文化藝術「走出去」的步伐，共建和諧美好社會環境。',
      coverImg: '/img/active/3.jpg',
      listImg: '/img/active/bg3.png'
    }
]
  public screenWidth = 0

  public pageNum = 1
  public pageSize = 12
  public total = 0
  public activityList: Array<SmsActivity> = []

  // 定义宽度

  public iframesrc = ''

  public iframewidth = 0

  public metaTitle = ''

  // 定义判断是否登录FB
  public FBLogin = sessionStorage.getItem('FBlogin') ? true : false

  metaList: Array<MetaRow> = []

  meta = setup(() => {
    const { meta } = useMeta({
      meta: this.metaList
    })
    return meta
  })

  getMonthActivity(monthId: number): void {
    api.subject_activity({ monthId }).then(res => {
      const articleAndActivityResultList = res.data.articleAndActivityResultList as Array<any>
      const arr: Array<SmsActivity> = []
      articleAndActivityResultList.forEach(item => {
        arr.push(...item.smsActivityList as Array<SmsActivity>)
      })
      // this.monthActivityList = arr
      this.getMeta()
    })
  }

  monthActivity(): JSX.Element {
    return <div class="activity_activity-month">
      <el-row>
        <el-col xs={24} sm={24} md={24} lg={18} xl={18}>
          <div class="activity_activity-month-left pointer">
            <div class="left-top">
              <div class="left-title">{this.monthActivityList[this.activeIndex]?.title}</div>
              <div class="left-info">
                {/* <div class="left-info-cell">
                  <i class="el-icon-date" />
                  <span>{
                    parseTime(new Date(this.monthActivityList[this.activeIndex]?.signUpStartTime as never).getTime(), '{y}.{m}.{d}')
                  }</span>
                  <span>-</span>
                  <span>{
                    parseTime(new Date(this.monthActivityList[this.activeIndex]?.signUpEndTime as never).getTime(), '{y}.{m}.{d}')
                  }</span>
                </div> */}
                <div class="left-info-cell">
                  <i class="el-icon-location-outline" />
                  <span>{this.monthActivityList[this.activeIndex]?.position}</span>
                </div>

                <div class="left-info-cell left-share">
                  <div>
                    <i>
                      <SvgIcon iconClass="share"></SvgIcon>
                    </i>
                    <span>分享</span>
                  </div>
                  {
                    this.monthActivityList[this.activeIndex]
                    && <div class="left-fun">
                      <share-network
                        network="facebook"
                        url={window.location.href}
                        title={this.monthActivityList[this.activeIndex].title}
                        description={this.monthActivityList[this.activeIndex].introduce}
                      >
                        <div class="facebook" />
                      </share-network>
                      <div class="wechat" onClick={() => sharer(2)} />
                      <share-network
                        network="whatsapp"
                        url={window.location.href}
                        title={this.monthActivityList[this.activeIndex].title}
                        description={this.monthActivityList[this.activeIndex].introduce}
                      >
                        <div class="phone" />
                      </share-network>
                    </div>
                  }
                </div>

              </div>
            </div>
            <div class="left-bottom" >
              <div class='direction direction-left'
                onClick={() => this.activeChange(this.activeIndex - 1)}
              >
                <i class='el-icon-arrow-left'></i>
              </div>
              <img
                src={this.monthActivityList[this.activeIndex]?.coverImg} alt={this.monthActivityList[this.activeIndex]?.title}
                onClick={() => this.goDetail(this.monthActivityList[this.activeIndex]?.id as number)}
              />
              <div class='direction direction-right'
                onClick={() => this.activeChange(this.activeIndex + 1)}
              >
                <i class='el-icon-arrow-right'></i>
              </div>
              {/* <div class="left-bottom-price">活动价格：HK$ {this.monthActivityList[this.activeIndex]?.price}</div> */}
            </div>

            {this.listDom()}
            {/* <el-pagination
              class="activity_activity-pagination"
              onCurrentChange={this.handleCurrentChange}
              currentPage={this.pageNum}
              pageSize={this.pageSize}
              layout="prev, pager, next, total"
              total={ this.total }
            /> */}
          </div>
        </el-col>
        <el-col xs={24} sm={24} md={24} lg={6} xl={6}>
          <div class="activity_activity-month-my-right">
            <div class='activity_activity-month-tab'>
              <img src="/fb.png" alt="" onClick={() => this.linkToFace()} />
              <img src="/ig.png" alt="" onClick={() => this.linkToIns()} />
            </div>

            {/* <iframe
              src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FTaosseract&tabs=timeline&width=360&height=860&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&adapt_container_width=true&appId=236655288380008"
              width="360"
              height="860"
              style="border:none;overflow:hidden"
              scrolling="no"
              frameborder="0"
              allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
              allowfullscreen="true"
              data-adapt-container-width="true"
              ></iframe> */}


            {/* <div id='fb-page' style='width:100%' class="fb-page"
              data-href="https://www.facebook.com/facebook" data-tabs="timeline" data-width="400" data-height="" data-small-header="false" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true"><blockquote cite="https://www.facebook.com/facebook" class="fb-xfbml-parse-ignore">< a href=" ">Facebook</ a></blockquote></div> */}

            {/* <div class="fb-page" data-href="https://www.facebook.com/Taosseract" data-tabs="timeline" data-width="250" data-height="850" data-show-text="true" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true"></div> */}
            <div class="fb-page" data-href="https://www.facebook.com/Taosseract/" data-tabs="timeline" data-width="250" data-height="850" data-small-header="false" data-adapt-container-width="false " data-hide-cover="false" data-show-facepile="false"><blockquote cite="https://www.facebook.com/Taosseract/" class="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/Taosseract/">Taosseract</a></blockquote></div>

            {/* <div class="activity_activity-month-instagram">
              <img src='/instagram.png'></img>
            </div> */}
            {/* <ul style={{
              flexFlow: this.screenWidth <= 1200 ? 'row' : 'column'
            }}>
              {this.monthActivityList.map((item, index) => <li
                onClick={() => this.activeChange(index)}
              >
                <div>{item.title}</div>
                <p style={{
                  maxHeight: '35px',
                  overflow: 'hidden'
                }}>{item.introduce}</p>
                <span>{parseTime(new Date(item.activityTime as never).getTime(), '{y}.{m}.{d}')}</span>
              </li>)}
            </ul> */}
          </div>
        </el-col>
      </el-row>
    </div>
  }

  getMeta(): void {
    this.metaList.splice(0, this.metaList.length)
    this.metaList.push(
      { property: 'og:title', content: this.monthActivityList[this.activeIndex]?.title as string },
      { property: 'og:description', content: this.monthActivityList[this.activeIndex]?.introduce as string },
      { property: 'og:image', content: this.monthActivityList[this.activeIndex]?.coverImg as string }
    )
  }

  activeChange(index: number): void {

    if (index === -1) {
      this.activeIndex = 2
    } else if (index === 3) {
      this.activeIndex = 0
    } else {
      this.activeIndex = index
    }
    this.getMeta()
  }

  getMounth(): void {
    const year = new Date().getFullYear() // 获取当年
    const month = new Date().getMonth() + 1 // 获取当月
    api.getMonth({ year }).then(res => {
      this.monthList = res.data.map((item: MonthData) => ({ name: item.fullName, value: item.id }))
      this.monthId = res.data.find((item: MonthData) => item.endMonth === month || item.startMonth === month)?.id
      this.getMonthActivity(this.monthId)
    })
  }

  getList(): void {
    api.getActivityAllList({
      pageNum: this.pageNum,
      pageSize: this.pageSize
    }).then(res => {
      this.total = res.data.total
      this.pageNum = res.data.pageNum
      this.pageSize = res.data.pageSize
      this.activityList = res.data.list
    })
  }

  goDetail(id: number): void {
    this.$router.push({
      name: 'ActivityActivityDetail',
      params: { id }
    })
  }
  linkToIns(): void {
    window.location.href = `https://instagram.com/taosseract?utm_medium=copy_link`
  }

  linkToFace(): void {
    window.location.href = `https://facebook.com/Taosseract`
  }

  checkLoginFB(): JSX.Element {
    // const login = this.FBLogin ? 'noLogin' : ''
    const result = this.FBLogin ? '为更好的查看网页内容, 请在浏览器上登录facebook'  : ''
    // return <div class={ this.FBLogin ? 'noLogin' : 'close' }>
    //   <div>
    //     {result}
    //   </div>
    //   <i class='el-icon-close' onClick={() => this.closeFBNotice()}></i>
    // </div>
    return <div></div>
  }

  closeFBNotice():void {
    this.FBLogin = false
  }


  listDom(): JSX.Element {
    return <div class="activity_activity-list">
      <el-row gutter={10}>
        {/* {this.activityList.map(item => ( */}
        {this.monthActivityList.map((item, index) => (
          // <div style={{backgroundColor: 'black', display:'flex', justifyContent: 'center',alignItems:'center' }}>
            // <el-col xs={24} sm={12} md={8} lg={8} xl={8} style={{backgroundColor: 'black',}}>
            <el-col xs={24} sm={12} md={8} lg={8} xl={8} > 
              <div class="list-cell"
                onClick={() => this.goDetail(this.monthActivityList[index]?.id as number)}
              >
                <img src={item.listImg} alt={item.title} style={{position: 'absolute', width: '100%', height: '100%', zIndex:0, opacity: 0.6, objectFit: 'fill'}}  />
                {/* <div  style={{position: 'absolute', width: '100%', height: '100%', zIndex:0, opacity: 0.4, background: `url(${item.listImg}) no-repeat`, backgroundSize: '100% 100%' }}  /> */}
                <div class="list-cell-top1">
                  <div class="list-cell-title">{item.title}</div>
                  <div class="list-cell-introduce">{item.introduce}</div>

                  <div class="list-cell-left">
                    <div class="list-cell-position">
                      {/* <i class="el-icon-location-outline"/>
                      <p>{item.position}</p> */}
                    </div>
                    {/* <span class="list-cell-time">{parseTime(new Date(item.activityTime as never).getTime(), '{y}.{m}.{d}')}</span> */}
                  </div>
                </div>
                <div class="list-cell-bottom">
                  {/* <span>HK$ {item.price}</span> */}
                  {/* <div class='icon-container'>

                      <p class='facebook-size'>
                        <SvgIcon iconClass='facebook2' ></SvgIcon>
                      </p>
                      <p class='instagram-size'>
                        <SvgIcon iconClass='Instagram'></SvgIcon>
                      </p>
                    </div> */}

                  {/* <div class='list-cell-btn' onClick={() => this.goDetail(item.id as number)}>立即参加</div> */}
                </div>
              </div>
            </el-col>
          // </div>
        ))}
      </el-row>
    </div>
  }

  handleCurrentChange(pageNum: number): void {
    this.pageNum = pageNum
    this.getList()
  }

  render(): JSX.Element {
    return this.$route.path === '/activity/activity'
      ? <div class="activity_activity">
        {this.checkLoginFB()}
        {this.monthActivity()}
        {/* <div class="activity_activity-line">
          <div>全部活动</div>
        </div> */}
        {/* {this.listDom()}
        <el-pagination
          class="activity_activity-pagination"
          onCurrentChange={this.handleCurrentChange}
          currentPage={this.pageNum}
          pageSize={this.pageSize}
          layout="prev, pager, next, total"
          total={ this.total }
        /> */}
      </div>
      : <RouterView />
  }
  mounted(): void {
    this.getMounth()
    this.screenWidth = document.body.clientWidth;
    window.onresize = () => {
      return (() => {
        this.screenWidth = document.body.clientWidth;
      })()
    }
    console.log(document.getElementById('fb-page')?.clientWidth);
    this.iframewidth = document.getElementById('fb-page')?.clientWidth as number

    this.getList()
  }
}


/**
 * @description 活动
 * @author Jinx
 * @date 2021-08-24 16:50:34
 * @export
 * @interface SmsActivity
 * @extends {DefaultSetting}
 */
export interface SmsActivity extends DefaultSetting {
  activityTime?: string // 活动时间
  articleImg?: string // 艺术家照片
  articleImgUrl?: string // 艺术家照片
  articleIntroduce?: string // 艺术家个人介绍
  articleId?: number // 艺术家名称
  coverImg?: string // 封面图片地址
  identity?: string // 报名身份
  introduce?: string // 活动综述
  mainImg?: string // 主图地址
  otherImg?: string // 其他图片地址
  peopleCount?: number // 已报名人数
  peopleNumber?: number // 报名人数
  point?: string // 活动积分
  position?: string // 活动地点
  price?: number // 活动价格
  signUpEndTime?: Date // 报名结束时间
  signUpStartTime?: Date // 报名开始时间
  title?: string // 活动名称
  signUpTime?: Date[] // 前端时间
  monthId?: string | number | Array<string | number>
}
